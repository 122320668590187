import * as amplitude from '@amplitude/analytics-browser'
import { environment } from 'src/environments/environment'
import { Injectable } from '@angular/core'
import * as shajs from 'sha.js'

@Injectable({
	providedIn: 'root'
})
export class AmplitudeAdapter {
	constructor() {}
	initAmplitude() {
		amplitude.init(environment.AMPLITUDE_KEY)
	}
	async track<T extends Partial<T>>(eventName: string, step?: number) {
		let payload: any = {
			device: this.getDeviceType(),
            application: this.getApplication(),
            project: 'legacy',
		}
		step && (payload.step = step)
		amplitude.track(eventName, payload)
	}
	setUserId(id: string): void {
		const identifier = shajs('sha256').update(id.toLocaleLowerCase()).digest('hex');
		amplitude.setUserId(identifier)
	}
	getApplication() {
		if (/anotaaibot/i.test(navigator.userAgent)) {
			return 'mobile_legacy'
		} else if (/anotaaimobileapp/i.test(navigator.userAgent)) {
			return 'mobile_flutter'
		} else {
			return 'browser'
		}
	}

    getDeviceType() {
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
          return 'mobile';
        }
    
        return 'desktop';
      }
}
