import { SidepanelService } from './../../sidepanel/sidepanel.service'
import { Component, ElementRef, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { AppComponent } from 'src/app/app.component'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { StorageService } from 'src/app/shared/services/storage/storage.service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { UtmsService } from 'src/app/shared/services/utms/utms.service'
import { SidepanelComponent } from 'src/app/sidepanel/sidepanel.component'
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service'
import { environment } from 'src/environments/environment'
import { AmplitudeAdapter } from 'src/app/shared/services/amplitude/amplitude.service'

@Component({
	selector: 'app-credentials',
	templateUrl: './credentials.component.html',
	styleUrls: ['./credentials.component.scss']
})
export class CredentialsComponent implements OnInit {
	public form: FormGroup
	public renderButton = true
	public passwordError: any
	public passwordConfirmationError: any
	constructor(
		private storageService: StorageService,
		private crmApiService: CrmApiService,
		private toastService: HotToastService,
		private router: Router,
		private utmsService: UtmsService,
		private trackerService: TrackersService,
		private sidepanelService: SidepanelService,
		private mixPanelService: MixPanelService,
		private amplitudeService: AmplitudeAdapter
	) {}

	ngOnInit(): void {
		this.sidepanelService.setStep(2)

		this.initForm()
		this.resolveBackButton()
		this.checkDeal()
		this.utmsService.setUtmCookies()
		window.scrollTo(0, 0)
		this.amplitudeService.track('View Sign Up', 2)
	}

	async checkDeal() {
		const { dealId } = this.storageService.getDeal()

		if (!dealId) {
			this.toastService.error('Complete o primeiro passo para prosseguir')
			return (window.location.href = 'https://anota.ai')
		}
		// this.crmApiService.updateDealStep(dealId, 'credentials-load').subscribe()

		const $req = this.crmApiService.getDeal(dealId).subscribe(
			(res) => {
				const { data: deal } = res
				this.storageService.updateForm({
					name: deal.name,
					phone: deal.contacts[0].phone,
					email: deal.contacts[0].email,
					company: deal.companies[0].name
				})
				const analyticsId = res.data.customFields.find(
					(field) => field.key === environment.UUID_KEY
				).value

				this.amplitudeService.setUserId(deal.contacts[0].email)
				this.storageService.updateForm({ analytics_id: analyticsId })
			},
			(err) => {
				this.toastService.error(
					'Erro ao carregar dados do negócio, tente novamente mais tarde'
				)
			},
			() => {
				$req.unsubscribe()
			}
		)
	}

	initForm(): void {
		this.form = new FormGroup({
			password: new FormControl('', [
				Validators.required,
				Validators.minLength(6)
			]),
			passwordConfirmation: new FormControl('', [
				Validators.required,
				Validators.minLength(6)
			])
		})

		this.form.valueChanges.subscribe(() => {
			this.passwordError = this.form.controls.password.errors?.minlength
				? 'Senha deve ter no mínimo 6 caracteres'
				: ''
			this.passwordConfirmationError = this.form.controls.passwordConfirmation
				.errors?.minlength
				? 'Senha deve ter no mínimo 6 caracteres'
				: ''
		})
	}

	goBack(): void {
		const utms = this.utmsService.getUtms()
		const mktTests = [
			'cta_test_gratis_vs_cta_livechat_original_forms',
			'cta_test_gratis_vs_cta_livechat_original_lv_header',
			'cta_test_gratis_vs_cta_livechat_original_lv_body',
			'cta_test_gratis_vs_cta_livechat_original_trial'
		]
		utms.utm_source == 'anota-app-mobile-new' || utms.utm_source == 'mobile-app' || mktTests.includes(utms.ab_test_mkt)
			? this.router.navigate(['/informations'], {
					queryParamsHandling: 'preserve'
			  })
			: (window.location.href = 'https://anota.ai')
	}

	submit(): void {
		if (this.form.invalid) return
		if (
			this.form.get('password').value !==
			this.form.get('passwordConfirmation').value
		) {
			this.passwordError = 'Senhas não conferem'
			this.passwordConfirmationError = 'Senhas não conferem'
			return
		}
		const { dealId } = this.storageService.getDeal()
		// this.crmApiService
		// 	.updateDealStep(dealId, 'credentials-confirmed')
		// 	.subscribe()
		const sessionForm = this.storageService.getForm()
		this.trackerService.track('CREATE-CREDENTIALS', sessionForm)
		this.mixPanelService.track('create-password', sessionForm.analytics_id)
		this.storageService.updateForm(this.form.value)
		this.router.navigate(['/importation'], {
			queryParamsHandling: 'preserve'
		})
	}

	resolveBackButton(): void {
		// if(this.navigationService.navigationBottomLimit === this.navigationService.currentStep.step){
		//   this.renderButton = false;
		// }
	}
}
