// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	BACKEND_URL: 'https://staging-freemium-api.anota-ai.com',
	CRM_API_URL: 'https://staging-crm-api.anota-ai.com',
	TAG_MANAGER_ID: 'GTM-PTG8KJK',
	ANOTAAI_ADMIN_URL: 'https://preprod-admin-front.anota-ai.com',
	OLD_BACKEND_URL: 'https://staging-ssanotaai-back.anota-ai.com',
	OLD_BACKEND_KEY:
		'eyJhbGciOiJIUzI1NiJ9.eyJpZHZlbmRvciI6IjVmNTBlZTFiNWVhMDdjMDAyMjRlNjQxMSJ9.27x5TRNoqjSFp8i0I7vZ3nr-DI6BIhNVjsPy4zmUSmo',
	ANOTAAI_AUTH_BACKEND_URL: 'https://staging-auth.anota-ai.com',
	ANOTAAI_RESPONDE_DOWNLOAD_URL:
		'https://staging-app.anota-ai.com/download-app/anotaai-desktop',
	ANOTAAI_RESPONDE_PROTOCOL: 'anotaaistg',
	PRINTER_URL: 'http://127.0.0.1:5000',
	ANOTAAI_DESKTOP_URL: 'http://127.0.0.1:420',
	NO_COMPUTER_LP: 'https://anota.ai/home/sem-computador',
	NO_COMPUTER_LANDBOT:
		'https://landbot.pro/v3/H-1347512-UC60DYVZAFULB0WN/index.html',
	MIX_PANEL_TRACKER_URL: 'https://staging-system-usage.api.anota-ai.com/action',
	UUID_KEY: '6369373afb084793432f927d',
	BOT_MOBILE_URL: 'https://staging-bot-mobile.anota-ai.com',
	REDIRECT_URL: 'https://staging-register-frontend.anota-ai.com/',
	INBOUND_URL: 'https://staging-inbound.anota-ai.com',
	AMPLITUDE_KEY:'7757097bd324d71343ab85a93839f5cf'
}	

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
