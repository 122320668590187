import { SidepanelService } from './../../sidepanel/sidepanel.service'
import { Component, OnInit } from '@angular/core'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { HotToastService } from '@ngneat/hot-toast'
import { CookieService } from 'ngx-cookie-service'
import { AppComponent } from 'src/app/app.component'
import { LoaderService } from 'src/app/shared/components/loader/loader.service'
import { CepService } from 'src/app/shared/services/cep/cep.service'
import { CrmApiService } from 'src/app/shared/services/crm-api/crm-api.service'
import { FreemiumApiService } from 'src/app/shared/services/freemium-api/freemium-api.service'
import { NavigationService } from 'src/app/shared/services/navigation/navigation.service'
import { StorageService } from 'src/app/shared/services/storage/storage.service'
import { TrackersService } from 'src/app/shared/services/trackers/trackers.service'
import { UtmsService } from 'src/app/shared/services/utms/utms.service'
import { SidepanelComponent } from 'src/app/sidepanel/sidepanel.component'
import { MixPanelService } from 'src/app/shared/services/mix-panel/mix-panel.service'
import { environment } from 'src/environments/environment'
import { Observable, last } from 'rxjs'
import { AmplitudeAdapter } from 'src/app/shared/services/amplitude/amplitude.service'

@Component({
	selector: 'app-import',
	templateUrl: './importation.component.html',
	styleUrls: ['./importation.component.scss']
})
export class ImportationComponent implements OnInit {
	public form: FormGroup
	public haveMenu: string
	public importationType: string
	public modalOpen: boolean = false
	public foundEstablishment: any = {}
	public chosenEstablishment: any
	public invalidLink: string
	public address: any
	public loading: boolean = false
	public notSelectedHaveMenu = ''
	public notSelectedImportationType = ''
	public notSelectedEstablishment = ''
	public globalDealId: string
	public analyticsId: string
	public vendorId: string
	public ifood_frn_id: string
	constructor(
		private navigationService: NavigationService,
		private utmsService: UtmsService,
		private storageService: StorageService,
		private crmApiService: CrmApiService,
		private freemiumApiService: FreemiumApiService,
		private loaderService: LoaderService,
		private toastService: HotToastService,
		private cookieService: CookieService,
		private router: Router,
		private trackerService: TrackersService,
		private sidepanelService: SidepanelService,
		private mixPanelService: MixPanelService,
		private route: ActivatedRoute,
		private amplitudeService: AmplitudeAdapter,
	) {}

	ngOnInit() {
		// this.loaderService.show('Carregando...')
		this.sidepanelService.setStep(3)
		this.initForm()
		this.checkDeal()
		this.utmsService.setUtmCookies()
		this.vendorId = this.route.snapshot.queryParamMap.get('vendorId')
		this.ifood_frn_id = this.route.snapshot.queryParamMap.get('ifood_frn_id')
		this.amplitudeService.track('View Sign Up', 3)
	}

	initForm(): void {
		this.form = new FormGroup({
			ifoodUrl: new FormControl(''),
			ifood_registred: new FormControl(''),
			cnpj: new FormControl('', [Validators.minLength(14)]),
			establishment: new FormControl(''),
			cep: new FormControl('', [Validators.minLength(8)]),
			street: new FormControl(''),
			number: new FormControl(''),
			complement: new FormControl('', [Validators.maxLength(50)]),
			neighborhood: new FormControl(''),
			city: new FormControl(''),
			state: new FormControl('')
		})
		this.form.controls.ifoodUrl.setErrors({ invalid: true })
		this.form.valueChanges.subscribe(() => {
			if (
				!this.form.value.ifoodUrl ||
				this.form.value.ifoodUrl.includes('https://www.ifood.com.br/delivery/')
			) {
				if (this.form.value.ifoodUrl) {
					this.form.controls.ifoodUrl.setErrors(null)
				}
				this.invalidLink = null
				return
			}
			this.invalidLink = 'Link inválido'
			this.form.controls.ifoodUrl.setErrors({ invalid: true })
		})
	}

	setHaveMenu(answer): void {
		const { moskitDealId } = this.storageService.getDeal()
		window.dataLayer.push({
			event: 'Register Type Selected',
			eventAction: answer,
			deal_id: moskitDealId
		})
		if (answer === 'Não') {
			this.form.get('ifood_registred').setValue(false)
			this.router.navigate(['/manual'], {
				queryParamsHandling: 'preserve'
			})
		} else {
			this.form.get('ifood_registred').setValue(true)
			this.sidepanelService.showManualStep(false)
			this.importationType = null
		}

		this.storageService.updateForm({ ifood_registred: answer === 'Sim' })
		this.form.get('establishment').setValue('')
		this.form.get('ifoodUrl').setValue('')
		this.form.get('cnpj').setValue('')
		this.haveMenu = answer
		this.notSelectedHaveMenu = 'not-selected'
		this.storageService.updateForm(this.form.value)
	}

	setImportationType(type): void {
		this.resetForm()
		let sessionForm = this.storageService.getForm()
		this.importationType = type
		this.notSelectedImportationType = 'not-selected'
		if (type === 'Manualmente') {
			this.trackerService.track('TRY-MANUAL-IMPORT')
			// this.crmApiService
			// 	.updateDealStep(this.globalDealId, 'try-manual-import')
			// 	.subscribe()
			this.mixPanelService.track('try-manual-import', this.analyticsId)
			this.router.navigate(['/manual'], {
				queryParamsHandling: 'preserve'
			})
		}
		if (type === 'Link') {
			this.trackerService.track('TRY-IMPORT-BY-LINK')
			// this.crmApiService
			// 	.updateDealStep(this.globalDealId, 'try-import-by-link')
			// 	.subscribe()
			this.mixPanelService.track('try-import-by-link', this.analyticsId)
		}
		if (type === 'CNPJ') {
			this.trackerService.track('TRY-IMPORT-COMPANY-BY-CNPJ')
			// this.crmApiService
			// 	.updateDealStep(this.globalDealId, 'try-import-company-by-cnpj')
			// 	.subscribe()
			this.mixPanelService.track('try-import-company-by-cnpj', this.analyticsId)
		}
		setTimeout(() => {
			window.scrollBy(0, 150)
		}, 200)
	}

	selectEstablishment(establishment) {
		this.chosenEstablishment = establishment
		this.notSelectedEstablishment = 'not-selected'
	}

	resetForm(): void {
		this.form.get('ifoodUrl').setValue('')
		this.foundEstablishment = {}
	}

	toggleModal(): void {
		this.modalOpen = !this.modalOpen
	}

	enableSubmit(): boolean {
		if (
			this.form.value.ifoodUrl.includes('https://www.ifood.com.br/delivery/') ||
			this.chosenEstablishment
		)
			return false
		return true
	}

	logChange(val) {}

	getEstablishmentsByCnpj() {
		if (
			this.form.controls.cnpj.errors?.minlength ||
			this.form.get('cnpj').value === ''
		)
			return
		this.loaderOn(true)
		try {
			this.freemiumApiService.getByCNPJ(this.form.get('cnpj').value).subscribe(
				(res) => {
					this.foundEstablishment = res
					this.loaderOn(false)
					if (this.foundEstablishment.data?.length === 0) {
						this.toastService.error(
							'Nenhum estabelecimento encontrado para este CNPJ, tente outro método de importação'
						)
						return
					}
					if (this.foundEstablishment.data?.length === 1) {
						this.chosenEstablishment = this.foundEstablishment.data[0]
						this.form
							.get('establishment')
							.setValue(this.chosenEstablishment.name)
						return
					}
				},
				(err) => {
					this.loaderOn(false)
					this.toastService.error('Erro ao buscar estabelecimentos')
				}
			)
		} catch {
			this.loaderOn(false)
			this.toastService.error('CNPJ inválido')
		}
	}

	goBack(): void {
		this.router.navigate(['/credentials'], {
			queryParamsHandling: 'preserve'
		})
	}

	async submit() {
		if (this.form.invalid) return
		this.loaderOn(true)

		const utms = this.utmsService.getUtms()

		const formData = this.storageService.getForm()

		const { moskitDealId } = this.storageService.getDeal()

		let data = {
			name: formData.name,
			password: formData.password,
			email: formData.email,
			phone: formData.phone,
			page_name: formData.company,
			computer: formData.computer == true ? 'Sim' : 'Não',
			crmId: moskitDealId,
			analytics_id: formData.analytics_id,
			device_category: this.getDevice(),
			merchant_ifood_frn_id: this.ifood_frn_id,
			vendorId: this.vendorId,
			ifood_registred: formData.ifood_registred,
			menu_import_by: this.importationType.toUpperCase(),
			...utms,
			admin_v2: 'NONE'
		}

		if (
			data.device_category == 'mobile' &&
			utms['utm_source'] != 'mobile-app' &&
			!utms.redirect
		) {
			let shouldBeCloudiaVar = false
			try {
				const { shouldBeCloudia } = await this.crmApiService.getIsCloudia()
				shouldBeCloudiaVar = shouldBeCloudia
			} catch {
				shouldBeCloudiaVar = true
			}
			if (
				shouldBeCloudiaVar ||
				utms.utm_source == 'teste2' ||
				utms.utm_source == 'teste4' ||
				utms.utm_source == 'anota-app-mobile-new'
			) {
				data['admin_v2'] = 'CLOUDIAv4'
				data['device_category'] = data['device_category'] + ' - CLOUDIAv4'
				data['bot_activation_type'] = 'CLOUDIA'
			} else {
				data['admin_v2'] = 'DEFAULT'
				data['device_category'] = data['device_category'] + ' - DEFAULT'
			}
		}

		if (this.form.get('ifoodUrl').value)
			data['url'] = this.form.get('ifoodUrl').value

		if (this.chosenEstablishment)
			data['url'] = this.chosenEstablishment.ifoodUrl

		Object.keys(data).forEach((key) =>
			data[key] == null || data[key] == 'undefined' ? delete data[key] : {}
		)

		this.freemiumApiService.createCompany(data).subscribe(
			(res: any) => {
				if (!res.success) {
					if (res.emailExists) {
						this.router.navigate(['/email-sent'], {
							queryParamsHandling: 'preserve'
						})
						this.trackerService.track('EMAIL-ALREADY-REGISTERED')
						this.loaderOn(false)
						return
					}
					this.loaderOn(false)
					this.toastService.error(
						'Ocorreu um erro ao cadastrar seu restaurante, tente novamente mais tarde'
					)
					return
				}
				let sessionForm = this.storageService.getForm()
				this.trackerService.track('CREATE-COMPANY')
				const $crawlerObs = this.freemiumApiService
					.getCompanyImportStatus(res.data.menuId)
					.subscribe((res: any) => {
						if (res.data.state === 'error') {
							this.loaderOn(false)
							this.form.enable()
							$crawlerObs.unsubscribe()
							this.toastService.error(res.data.message)

							return
						}
						if (res.data.state === 'completed') {
							$crawlerObs.unsubscribe()

							let expiryDate = new Date()
							expiryDate.setMonth(expiryDate.getMonth() + 2)

							this.cookieService.set(
								'ADMIN_TOKEN',
								JSON.stringify(res.data.token),
								expiryDate,
								'/'
							)
							this.trackerService.track('IFOOD-IMPORT', sessionForm)
							this.trackerService.track('ACCOUNT-CREATED', sessionForm)

							this.importationType === 'Link'
								? this.mixPanelService.track(
										'ifood-link-imported',
										this.analyticsId
								  )
								: this.mixPanelService.track('cnpj-imported', this.analyticsId)
							this.loaderOn(false)

							this.amplitudeService.track('Callback Sign Up Register Account')
							if (utms.redirect) {
								console.log('Redirecting to: ', utms.redirect)
								window.location.href = utms.redirect
								return
							}

							if (data.admin_v2 == 'CLOUDIAv4') {
								window.location.href = `${environment.ANOTAAI_ADMIN_URL}/access/${res.data.token}`
								return
							}

							this.router.navigate(['/thanks'], {
								queryParamsHandling: 'preserve'
							})

							return
						}
					})
			},
			(err) => {
				this.loaderOn(false)
				this.toastService.error(
					'Ocorreu um erro ao cadastrar seu restaurante, tente novamente mais tarde'
				)
				return
			}
		)
	}

	public getDevice() {
		if (
			/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
				navigator.userAgent
			)
		) {
			return 'mobile'
		} else {
			return 'desktop'
		}
	}

	loaderOn(val: boolean): void {
		this.loading = val
	}

	async checkDeal() {
		const { dealId } = this.storageService.getDeal()
		const { password } = this.storageService.getForm()
		if (!password) {
			this.toastService.error('Defina uma senha para continuar')
			return this.router.navigate(['/credentials'], {
				queryParamsHandling: 'preserve'
			})
		}

		if (!dealId) {
			return this.router.navigate(['/informations'], {
				queryParamsHandling: 'preserve'
			})
		}

		this.globalDealId = dealId
		const $req = this.crmApiService.getDeal(dealId).subscribe(
			(res) => {
				const { data: deal } = res
				this.storageService.updateForm({
					name: deal.name,
					phone: deal.contacts[0].phone,
					email: deal.contacts[0].email,
					company: deal.companies[0].name
				})

				const analyticsId = res.data.customFields.find(
					(field) => field.key === environment.UUID_KEY
				).value
				this.amplitudeService.setUserId(deal.contacts[0].email)
				this.storageService.updateForm({ analytics_id: analyticsId })
				this.analyticsId = analyticsId
			},
			(err) => {
				this.toastService.error(
					'Erro ao carregar dados do negócio, tente novamente mais tarde'
				)
			},
			() => {
				$req.unsubscribe()
			}
		)

		try {
			const { data } = await this.crmApiService.getDeal(dealId).toPromise()

			this.storageService.setDeal({
				dealId: data._id,
				moskitDealId: data._id
			})
		} catch (error) {
			this.storageService.setDeal()
			this.router.navigate(['/'], {
				queryParamsHandling: 'preserve'
			})
		}
	}
}
