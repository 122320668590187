import { Injectable } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { UTM } from '../utms/utms.service'
import { BehaviorSubject } from 'rxjs'

@Injectable({
	providedIn: 'root'
})
export class StorageService {
	public accountAlreadyUsed: boolean = false

	public isLP: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)

	constructor(
		private cookieService: CookieService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	getForm() {
		const getSessionSignUpForm = sessionStorage.getItem(
			'@anota-ai/initiate/register'
		)

		return JSON.parse(getSessionSignUpForm) ?? {}
	}

	updateForm(data: object) {
		const signUpForm = this.getForm()

		const result = Object.assign(signUpForm, data)

		sessionStorage.setItem(
			'@anota-ai/initiate/register',
			JSON.stringify(result)
		)
	}

	clearForm() {
		sessionStorage.removeItem('@anota-ai/initiate/register')
		sessionStorage.removeItem('@anota-ai/initiate/form')
	}

	getDeal(): { dealId: string; moskitDealId: string } {
		const dealIdsOnCookies = this.cookieService.get('DEAL_IDS')
		const dealIds = dealIdsOnCookies && JSON.parse(dealIdsOnCookies)
		const dealIdOnQuery = this.route.snapshot.queryParamMap.get('deal_id')
		return {
			dealId: dealIdOnQuery ?? dealIds?.dealId,
			moskitDealId: dealIds.moskitDealId
		}
	}

	setDeal(dealIds?: { dealId: string; moskitDealId?: string }): void {
		if (!dealIds) {
			this.cookieService.delete('DEAL_IDS')
			return
		}

		let expiryDate = new Date()
		expiryDate.setMonth(expiryDate.getMonth() + 2)

		this.cookieService.set('DEAL_IDS', JSON.stringify(dealIds), expiryDate, '/')
	}

	getLPData() {
		this.setUpStagingEnv()
		const lpData: any = this.cookieService.get('lp-form')
			? JSON.parse(this.cookieService.get('lp-form'))
			: null

		const form = sessionStorage.getItem('@anota-ai/initiate/form')

		const dealIdOnQuery = this.router.parseUrl(this.router.url).queryParams
			.id_deal

		if (!lpData || form || dealIdOnQuery) return this.changeIsLp(false)

		lpData.computer === 'Sim'
			? (lpData.computer = true)
			: (lpData.computer = false)
		lpData.name = lpData.first_name

		this.clearForm()
		this.updateForm(lpData)
		const UTMModel: UTM = {
			utm_source: lpData.utm_source,
			utm_medium: lpData.utm_medium,
			utm_campaign: lpData.utm_campaign,
			utm_term: lpData.utm_term,
			utm_content: lpData.utm_content,
			application: lpData.application,
			partner: lpData.partner,
			gclid: lpData.gclid,
			fbclid: lpData.fbclid,
			redirect_by: lpData.redirect_by,
			ifood_merchant_id: lpData.ifood_merchant_id,
			indicado_aid: lpData.indicado_aid,
			terceirizado: lpData.terceirizado,
			freemium: lpData.freemium,
			admin_v2: lpData.admin_v2,
			ref: lpData.ref,
			waiter_quantity: lpData.waiter_quantity,
			waiter_tables: lpData.waiter_tables,
			ab_test_mkt: lpData.ab_test_mkt,
			bid: lpData.bid,
			click_id:lpData.click_id
		}

		Object.keys(UTMModel).forEach((key) => {
			if (UTMModel[key] === undefined) return
			if (key === 'application') {
				this.cookieService.set(
					key,
					UTMModel[key],
					new Date(new Date().getTime() + 60 * 60 * 1000)
				)
				return
			}
			this.cookieService.set(key, UTMModel[key])
		})

		let shareableCode = lpData.shareableCode
		if (!shareableCode)
			shareableCode = sessionStorage.getItem('@anota-ai/initiate/shcode')
		else sessionStorage.setItem('@anota-ai/initiate/shcode', shareableCode)

		this.cookieService.set('lp-form', '', -1)
		this.cookieService.delete('lp-form')

		return this.changeIsLp(true)
	}

	changeIsLp(val: boolean) {
		if (val == false) {
			this.cookieService.set('lp-form', '', -1)
			this.cookieService.delete('lp-form')
		}
		this.isLP.next(val)
	}

	checkIsLp() {
		return this.isLP.asObservable()
	}

	setUpStagingEnv() {
		const staging =
			location.hostname.includes('staging') ||
			location.hostname.includes('localhost')
		if (!staging) return

		let formBody = this.route.snapshot.queryParams.formBody
		let haveFormBody = this.getForm().first_name

		if (formBody && !haveFormBody) {
			this.cookieService.set('lp-form', formBody)
		}
	}

	// 	<script>
	// 	var xhr = new XMLHttpRequest();
	// 	var formBody = JSON.parse(sessionStorage.getItem("@anotaai/elementor-form"))
	// 	var params = new URLSearchParams(location.search);
	// 	var shareableCode = params.get('shareableCode');
	// 	var curadoria = params.get('curadoria');

	// 	formBody["analytics_id"] = {{client_id}};
	// 	formBody["partner"] = {{Page_partner}};
	// 	formBody["lead_ip"] = {{ipify}}.ip;
	// 	formBody["analytics_id"] = {{client_id}};
	// 	formBody["shareableCode"] = shareableCode

	// 	 function setCookie(name, value, days){
	// 		var date = new Date();
	// 		date.setTime(date.getTime() + (days*24*60*60*1000));
	// 		var expires = "; expires=" + date.toGMTString();
	// 		document.cookie = name + "=" + value + expires + ";path=/;domain=anota.ai";
	// 	}

	// 	setCookie('lp-form', JSON.stringify(formBody),30)

	// 	console.log(formBody)

	// 	if(formBody["first_name"].includes('staging') && formBody["email"].includes('anota.ai')){
	// 	  //envia formbody na query pra simular comportamento de prod
	// 	  var queryBody = JSON.stringify(formBody)
	// 	   window.location.assign('https://staging-register-frontend-beta.anota-ai.com/informations?formBody='+queryBody)
	// 	   }
	// 	 else{
	// 	  window.location.assign('https://cadastro.anota.ai/informations');
	// 	 }
	//   </script>
}
