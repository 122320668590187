import { Injectable } from '@angular/core'
import { GoogleTagManagerService } from 'angular-google-tag-manager'
import { CookieService } from 'ngx-cookie-service'
import { environment } from 'src/environments/environment'
import * as shajs from 'sha.js'
import { ActivatedRoute } from '@angular/router'
import { WindowRefService } from '../window/window-ref.service'
import { CustomWindow } from '../window/interfaces/custom-window'

@Injectable({
	providedIn: 'root'
})
export class TrackersService {
	public customWindow: CustomWindow
	constructor(
		private gtmService: GoogleTagManagerService,
		private cookieService: CookieService,
		private route: ActivatedRoute,
		private windowRef: WindowRefService
	) {}

	initTrackers() {
		this.customWindow = this.windowRef.getNativeWindow()
	}

	public track(eventType: string, eventData?: any): void {
		const qa_mode = this.route.snapshot.queryParamMap.get('qa_mode')
		eventData ? this.sendAppsFlyer(eventType, eventData) : null
		if (!environment.production || qa_mode) return
		const gtmTag = {
			event: eventType,
			data: eventData ? this.facebookConversion(eventData) : undefined,
			event_id: eventData
				? shajs('sha256')
						.update(
							eventType +
								eventData?.email?.trim().toLowerCase() +
								new Date().getDate()
						)
						.digest('hex')
				: undefined
		}
		this.gtmService.pushTag(gtmTag)
	}

	public viewPage(url: string): void {
		const qa_mode = this.route.snapshot.queryParamMap.get('qa_mode')
		if (!environment.production || qa_mode) return
		const gtmTag = {
			event: 'page',
			pageName: url
		}
		this.gtmService.pushTag(gtmTag)
	}

	public facebookConversion(payload) {
		const fbc = this.cookieService.get('_fbc')
		const fbp = this.cookieService.get('_fbp')
		let toHash = payload.email

		if (payload.phone) {
			payload.phone = '55' + payload.phone.replace(/[\(\)\-\s]/g, '')
		}

		let facebookPayload = {
			em: payload?.email,
			fn: payload?.name ? payload?.name.split(' ')[0] : null,
			ln: payload?.name ? payload?.name.split(' ').slice(1).join(' ') : null,
			ph: payload?.phone,
			ct: payload?.address?.city,
			st: payload?.address?.state,
			zp: payload?.address?.postalCode,
			country: 'br',
			extern_id: toHash
				? shajs('sha256').update(toHash).digest('hex')
				: undefined,
			external_id: toHash
				? shajs('sha256').update(toHash).digest('hex')
				: undefined,
			client_user_agent: navigator.userAgent,
			fbp: fbp,
			fbc: fbc
		}
		// remove the undefined keys
		Object.keys(facebookPayload).forEach((key) =>
			facebookPayload[key] == null || facebookPayload[key] == 'undefined'
				? delete facebookPayload[key]
				: {}
		)

		return facebookPayload
	}

	sendAppsFlyer(eventName, payload) {
		let objPayload = {
			event_name: eventName,
			name: payload.name,
			email: payload.email,
			page_name: payload.company,
			phone: payload.phone,
			computer: payload.computer == true ? 'Sim' : 'Não',
			timestamp: new Date().getTime(),
			...payload.utms
		}

		this.customWindow?.appsflyerEvent?.postMessage(JSON.stringify(objPayload))
		this.customWindow?.appsflyerWaiter?.postMessage(JSON.stringify(objPayload))
	}
}
